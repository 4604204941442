@import "../../styles/variables.scss";

span.status-dot {
  width: 6px;
  height: 6px;
  cursor: pointer;
  border-radius: 50%;
  margin-right: 0.5rem;
  display: inline-block;
  background-color: $color-grey-hapi-1;

  &.green,
  &.active {
    background-color: $color-green-hapi-1;
  }
  &.yellow,
  &.pending {
    background-color: $color-yellow-hapi-3;
  }
  &.red,
  &.frozen {
    background-color: $color-red-hapi-1;
  }
}
