@import "../../styles/variables.scss";

.countdown-timer {
  display: flex;
  color: $color-white;
  justify-content: center;
  > span {
    width: 2rem;
    span {
      display: block;
      font-weight: 400;
      &:first-child {
        font-size: 1rem;
      }
      &:last-child {
        font-size: 0.75rem;
        margin-top: 0.25rem;
        color: $color-grey-hapi-1;
        text-transform: uppercase;
      }
    }
  }
}
