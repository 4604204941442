@media screen and (min-width: 576px) and (max-width: 767px) {
    /*TYPOGRAPHY*/
    h1 {
        font-size: 1.5625rem;
    }
    /*END OF TYPOGRAPHY*/
    /*FORM*/
    form {
        .radio-group {
            .radio-group-item {
                label {
                    padding: 1.125rem 1.6875rem;
                }
            }
        }
    }
    /*END OF FORM*/
}