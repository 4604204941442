@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";
span.collapse-control {
    line-height: 0;
    cursor: pointer;
    margin-left: 1.3125rem;
    svg {
        @include transition;
        path {
            @include transition;
        }
    }
    &.open {
        svg {
            transform: rotate(180deg);
            path {
                fill: $color-yellow-hapi-1;
            }
        }
    }
}