@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
.button {
  border: 0;
  max-width: 100%;
  cursor: pointer;
  font-weight: 700;
  font-size: 1.25rem;
  text-align: center;
  border-radius: $br-8;
  box-sizing: border-box;
  padding: 0.75rem 1.5rem;
  -webkit-appearance: none;
  color: $color-black-hapi-1;
  background-color: $color-yellow-hapi-1;
  font-family: Arial, Helvetica, sans-serif;
  @include transition();

  &.red {
    color: $color-white;
    background-color: $color-red-hapi-1;

    &:hover {
      background-color: darken($color-red-hapi-1, 10%);
    }
  }

  &:hover {
    background-color: $color-yellow-hapi-2;
  }
  span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &[disabled="disabled"],
  &:disabled {
    cursor: not-allowed;
    background-color: $color-yellow-hapi-5;
  }
  /*POSITIONS*/
  &.clear {
    padding: 0 !important;
  }
  &.clear-margin {
    margin: 0 !important;
  }
  &.left {
    margin: 0 auto 0 0;
  }
  &.right {
    margin: 0 0 0 auto;
  }
  &.center {
    margin: 0 auto;
  }
  /*END OF POSITIONS*/
  /*EMPTY*/
  &.empty {
    color: $color-yellow-hapi-1;
    background-color: transparent;

    &[disabled="disabled"],
    &:disabled {
      color: $color-yellow-hapi-5;

      svg {
        path {
          fill: $color-yellow-hapi-5;
        }
      }

      &:hover {
        color: $color-yellow-hapi-5;
        svg {
          path {
            fill: $color-yellow-hapi-5;
          }
        }
      }
    }

    &.red {
      color: $color-red-hapi-1 !important;
      background-color: transparent !important;
    }

    &.grey {
      color: $color-grey-hapi-1 !important;

      svg {
        path {
          fill: $color-grey-hapi-1 !important;
        }
      }
    }

    &.white {
      color: $color-white;

      svg {
        path {
          fill: $color-white;
        }
      }

      &:hover {
        color: $color-grey-hapi-1;

        svg {
          path {
            fill: $color-grey-hapi-1;
          }
        }
      }
    }

    svg {
      path {
        fill: $color-yellow-hapi-1;
        @include transition();
      }
    }
    &:hover {
      color: $color-yellow-hapi-2;
      svg {
        path {
          fill: $color-yellow-hapi-2;
        }
      }
    }
  }
  /*END OF EMPTY*/
  /*OUTLINED*/
  &.outlined {
    padding: 0.6875rem 1.5rem;
    color: $color-yellow-hapi-1;
    background-color: transparent;
    border: 1px solid $color-yellow-hapi-1;
    &:hover {
      color: $color-yellow-hapi-2;
      border: 1px solid $color-yellow-hapi-2;
    }
    &.span-center {
      span {
        text-align: center;
      }
    }
    &.medium {
      padding: 0.625rem 1.5rem;
    }
    &.small {
      padding: 0.3125rem 0.75rem;
    }
    svg {
      path {
        fill: $color-yellow-hapi-1;
      }
    }
    &.yellow-hapi-2 {
      color: $color-yellow-hapi-2;
      background-color: transparent;
      border: 1px solid $color-yellow-hapi-2;
      svg {
        path {
          fill: $color-yellow-hapi-2;
        }
      }
    }
    &.yellow-hapi-3 {
      color: $color-yellow-hapi-3;
      background-color: transparent;
      border: 1px solid $color-yellow-hapi-3;
      svg {
        path {
          fill: $color-yellow-hapi-3;
        }
      }
    }
    &.grey-hapi-1 {
      color: $color-grey-hapi-1;
      background-color: transparent;
      border: 1px solid $color-grey-hapi-1;
      svg {
        path {
          fill: $color-grey-hapi-1;
        }
      }
    }
    &.grey-hapi-2 {
      color: $color-grey-hapi-2;
      background-color: transparent;
      border: 1px solid $color-grey-hapi-2;
      svg {
        path {
          fill: $color-grey-hapi-2;
        }
      }
    }
    &.grey-hapi-3 {
      color: $color-grey-hapi-3;
      background-color: transparent;
      border: 1px solid $color-grey-hapi-3;
      svg {
        path {
          fill: $color-grey-hapi-3;
        }
      }
    }
  }
  /*END OF OUTLINED*/
  /*SIZES*/
  &.medium {
    font-size: 1rem;
    padding: 0.6875rem 1.5rem;
    &.with-icon {
      padding: 0.4687rem 1rem;
      svg {
        height: 25px;
      }
    }
  }
  &.small {
    font-weight: 400;
    font-size: 0.75rem;
    padding: 0.375rem 0.75rem;
    &.with-icon {
      height: 20px;
    }

    &.with-icon.two-icons {
      svg {
        &:first-child {
          margin-right: 0.25rem;
        }
      }
    }

    svg {
      width: 15px !important;
      height: auto !important;

      &:first-child {
        margin-right: 0.5rem;
      }
    }

    &.empty {
      color: $color-yellow-hapi-1;
      background-color: transparent;

      svg {
        path {
          fill: $color-yellow-hapi-1;
          @include transition();
        }
      }
      &:hover {
        color: $color-yellow-hapi-2;
        svg {
          path {
            fill: $color-yellow-hapi-2;
          }
        }
      }
    }
  }
  /*END OF SIZES*/
  /*COLORS*/
  &.yellow-hapi-2 {
    background-color: $color-yellow-hapi-2;
  }
  &.yellow-hapi-3 {
    background-color: $color-yellow-hapi-3;
  }
  &.white-hapi-1 {
    background-color: $color-white;
  }
  &.white-hapi-2 {
    background-color: $color-white-hapi-2;
  }
  &.white-hapi-3 {
    background-color: $color-white-hapi-3;
  }
  &.grey-hapi-1 {
    color: $color-white;
    background-color: $color-black-hapi-2;
  }
  /*END OF COLORS*/
  /*ADDITIONAL CLASSES*/
  &.full-width {
    width: 100%;
  }
  &.with-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: auto;
      height: 30px;
      margin-left: 0.5625rem;
    }
    &.icon-left {
      span {
        order: 2;
      }
      svg {
        order: 1;
        margin-left: 0px;
        margin-right: 0.5625rem;
      }
    }
    &.two-icons {
      justify-content: flex-start;
      span {
        width: 70%;
      }
      svg {
        &:first-child {
          margin-left: 0;
          margin-right: 1.25rem;
        }
        &:last-child {
          margin: 0 0 0 auto;
        }
      }
    }
  }
  /*END OF ADDITIONAL CLASSES*/
}

/*ADAPTIVE*/

@media screen and (min-width: 992px) and (max-width: 1199px) {
}

@media screen and (min-width: 768px) and (max-width: 991px) {
}

@media screen and (min-width: 576px) and (max-width: 767px) {
}

@media screen and (max-width: 575px) {
}

/*END OF ADAPTIVE*/
