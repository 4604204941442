@import "../../../styles/variables.scss";

.confirm-modal {
  div.two-buttons {
    button {
      &:last-child {
        color: $color-white;
        background-color: $color-red-hapi-1;

        &:hover {
          background-color: darken($color-red-hapi-1, 5%);
        }
      }
    }
  }
}
