@import "../../styles/variables.scss";
div.search {
  text-align: left;
  position: relative;
  display: inline-block;
  span.clear-search {
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
  }
  &:before {
    content: "";
    top: 50%;
    left: 14px;
    width: 24px;
    z-index: -1;
    height: 24px;
    position: absolute;
    display: inline-block;
    background-size: 24px;
    transform: translateY(-50%);
    background-image: url("../../images/icon-pack/search.svg");
  }
  input {
    width: 328px;
    max-width: 100%;
    font-size: 1rem;
    font-weight: 400;
    color: $color-white;
    border-radius: $br-8;
    padding: 0.625rem 3rem;
    box-sizing: border-box;
    background-color: transparent;
    border: 1px solid $color-grey-hapi-1;
    &:focus {
      outline: unset;
      border: 1px solid $color-yellow-hapi-1;
    }
    &::placeholder {
      font-size: 1rem;
      font-weight: 400;
      color: $color-grey-hapi-1;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  div.search {
    input {
      width: 298px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  div.search {
    input {
      width: 378px;
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  div.search {
    input {
      width: 307px;
    }
  }
}

@media screen and (max-width: 575px) {
  div.search {
    width: 100%;
    input {
      width: 100%;
    }
  }
}
