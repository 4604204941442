@import "../../styles/variables.scss";
div.search-n-button {
  display: flex;
  text-align: left;
  justify-content: space-between;
  div.search {
    input {
      margin-top: 0;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
}

@media screen and (min-width: 768px) and (max-width: 991px) {
}

@media screen and (min-width: 576px) and (max-width: 767px) {
}

@media screen and (max-width: 575px) {
  div.search-n-button {
    display: block;
    div.button-wrapper {
      left: 0;
      bottom: 0;
      z-index: 1;
      width: 100%;
      padding: 1rem;
      display: block;
      position: fixed;
      box-sizing: border-box;
      background: $color-black-hapi-1;
      button {
        width: 100%;
      }
    }
  }
}
