@import "../../styles/variables.scss";
span.sticker {
  font-weight: 400;
  font-size: 0.75rem;
  color: $color-white;
  border-radius: $br-2;
  display: inline-block;
  padding: 0.125rem 0.25rem;
  background-color: $color-grey-hapi-7;
  &.red,
  &.validator {
    background-color: $color-red-hapi-2;
  }
  &.blue,
  &.authority {
    background-color: $color-blue-hapi-1;
  }
  &.green,
  &.publisher {
    background-color: $color-green-hapi-3;
  }
  &.yellow,
  &.tracer {
    background-color: $color-yellow-hapi-7;
  }
}
