@import "../../../styles/variables.scss";

.dropdown-menu {
  .dropdown-content {
    div {
      &.headline {
        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
          display: flex;
          align-items: center;
        }
      }

      &.balance-block {
        display: flex;
        margin-top: 1rem;
        align-items: flex-end;
        justify-content: space-between;

        div.balance {
          margin: 0;
          padding: 0;
          text-align: left;
        }

        button {
          svg {
            &:last-child {
              transform: rotate(90deg);
            }
          }
        }
      }

      &.message-toast {
        margin-top: 1rem;
      }
    }

    button {
      &.wallet-adapter-button {
        width: 100%;
        margin-top: 1rem;
        justify-content: center !important;
        color: $color-grey-hapi-1 !important;
        background-color: transparent !important;
        border: 1px solid $color-grey-hapi-1 !important;

        &:before {
          content: unset;
        }
      }
    }
  }
}
