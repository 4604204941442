@import "../../styles/variables.scss";
.modal-wrapper {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.6);
}
.modal-body {
  z-index: 200;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 305px;
  overflow: auto;
  max-width: 100%;
  margin-bottom: 6rem;
  border-radius: $br-16;
  box-sizing: border-box;
  background-color: $color-black-hapi-3;

  .text-container {
    font-size: 1rem;
    font-weight: 400;
    margin: 1rem 0 3rem 0;
    color: $color-grey-hapi-1;

    &.clear-margin {
      margin: 0;
    }
  }
  &.case-modal {
    max-height: 80vh;
    &.reporter-info {
      width: 650px;
    }
  }
  &.alert {
    text-align: center;
    p {
      font-weight: 400;
      font-size: 1.5rem;
    }
    svg {
      width: 65px;
    }
    &.ok {
      svg {
        path {
          fill: $color-green;
        }
      }
      p {
        color: $color-green;
      }
    }
    &.not-ok {
      svg {
        path {
          fill: $color-red;
        }
      }
      p {
        color: $color-red;
      }
    }
  }
  p {
    margin-top: 0;
    font-weight: 700;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    color: $color-white;
  }
}
