div.modal-body {
    &.case-modal {
        width: 450px;
        div.button-wrapper {
            margin: 1.25rem 0;
        }
        div.addresses-wrapper {
            margin: 1rem 0;
        }
    }
}