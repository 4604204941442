@import '../../../styles/variables.scss';
div.input-message {
    margin-top: 0.25rem;
    &.success {
        color: $color-green-hapi-1;
    }
    &.fail {
        color: $color-red-hapi-1;
    }
    span {
        display: block;
        font-weight: 400;
        font-size: 0.75rem;
    }
}