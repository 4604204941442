@import "../../../styles/variables.scss";

div.two-buttons {
  bottom: 0;
  display: flex;
  position: sticky;
  width: calc(100% - 1.5rem);
  background-color: $color-black-hapi-3;
  padding: 2.5rem 0.75rem 0.75rem 0.75rem;

  &.with-additional {
    flex-wrap: wrap;

    button {
      &:nth-child(2) {
        margin-right: 0.75rem;
      }
      &:last-child {
        width: calc(75% - 0.75rem);
      }
    }

    div.additional-button {
      width: 100%;
      text-align: center;

      button {
        margin-right: 0;
      }
    }
  }

  button {
    &:first-child {
      width: 25%;
      margin-right: 0.75rem;
    }
    &:last-child {
      width: 75%;
    }
  }
}
