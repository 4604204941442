@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";
div.address-info {
  padding: 0.75rem;
  margin-bottom: 1rem;
  border-radius: $br-8;
  background-color: $color-grey-hapi-6;
  &:last-child {
    margin-bottom: 0;
  }
  p {
    margin: 0;
    font-weight: 400;
    overflow: hidden;
    font-size: 0.875rem;
    color: $color-white;
    margin-bottom: 0.5rem;
    box-sizing: border-box;
    word-break: break-all;
    white-space: break-spaces;

    &.with-edit {
      display: flex;
      margin-bottom: 0.3em;
      align-items: flex-start;
      justify-content: space-between;

      span {
        width: 90%;
        overflow: hidden;
        word-break: break-all;
        white-space: break-spaces;
      }

      button {
        &.edit-button {
          margin: 0;
          line-height: 0;

          svg {
            width: 20px;
            height: auto;
          }
        }
      }
    }
  }
  p + div {
    display: flex;
    align-items: center;

    span {
      font-weight: 400;
      font-size: 0.75rem;
      color: $color-white;
    }
  }
  > div {
    div.confirm-block {
      margin-top: 0;
      margin-left: 0.5rem;
    }
  }
  div.confirm-block {
    margin-top: 0.5rem;
  }
  span {
    &.sticker,
    &.blockchain-label {
      margin-left: 0.5rem;
    }
  }
}

@media screen and (min-width: 1200px) {
  div.address-info {
    &:hover {
      p {
        &.with-edit {
          button {
            opacity: 1;
          }
        }
      }
    }
    p {
      &.with-edit {
        button {
          opacity: 0;
          @include transition;
        }
      }
    }
  }
}
