@import "./styles/variables.scss";
@import "./styles/mixins.scss";
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  padding-bottom: 2.5rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: $color-black-hapi-1;
  font-family: Arial, Helvetica, sans-serif;
}
div.App {
  div.container {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }

  div.content {
    flex: 1 0 auto;
  }
}

/*TYPOGRAPHY*/

h1,
h2 {
  margin: 0;
  text-align: left;
  color: $color-white;
  text-transform: uppercase;
  font-family: "Druk Wide Cy TT Heavy";
}

h1 {
  font-weight: 900;
  font-size: 2.25rem;
  font-style: normal;
}
/*END OF TYPOGRAPHY*/

img {
  max-width: 100%;
}
/*FORM DEFAULT STYLES*/

.form-wrapper {
  border-radius: $br-16;
  padding: 1.3125rem 1rem;
  background-color: $color-black-hapi-3;
}

//FOCUS REMOVE
textarea,
input,
select {
  &:focus {
    outline: none;
    border: 1px solid $color-yellow-hapi-1;
  }
}

span {
  &.label {
    display: block;
    font-size: 1rem;
    text-align: left;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $color-grey-hapi-1;
  }
}

// LABEL TAG
label {
  display: block;
  font-size: 1rem;
  text-align: left;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: $color-grey-hapi-1;
  &.medium {
    font-size: 0.75rem;
  }
}

// ALL INPUTS & SELECTS TAGS
input,
select {
  width: 100%;
  border: none;
  display: block;
  overflow: hidden;
  font-size: 1.5rem;
  margin-top: 0.25rem;
  white-space: nowrap;
  color: $color-white;
  border-radius: $br-8;
  padding: 1rem 0.75rem;
  box-sizing: border-box;
  text-overflow: ellipsis;
  border: 1px solid $color-grey-hapi-4;
  background-color: $color-black-hapi-2;
  &.medium {
    font-size: 0.85rem;
    padding: 0.7rem 0.75rem;
  }
}

//ALL INPUTS
input {
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &[type="submit"] {
    @include transition;
    cursor: pointer;
    font-weight: 700;
    margin-top: 1.5rem;
    font-size: 1.25rem;
    text-align: center;
    color: $color-black;
    padding: 1.125rem 1.5rem;
    -webkit-appearance: none;
    border: 1px solid $color-yellow-hapi-1;
    background-color: $color-yellow-hapi-1;
    @include transition();
    &:hover {
      border: 1px solid $color-yellow-hapi-2;
      background-color: $color-yellow-hapi-2;
    }
    &:disabled {
      border-color: $color-yellow-hapi-5;
      background-color: $color-yellow-hapi-5;
    }
  }
  &[type="text"] {
    -webkit-appearance: none;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

//SELECTS
select {
  cursor: pointer;
  -webkit-appearance: none;
  background: url(./images/html-components/select/select-arrow.png) no-repeat;
  background-position-y: center;
  padding: 1rem 1.7rem 1rem 0.75rem;
  background-color: $color-black-hapi-2;
  background-position-x: calc(100% - 10px);
}

// RADIOBUTTONS
.radio-group {
  span.label {
    font-size: 0.75rem;
    margin-bottom: 1.125rem;
  }
  label {
    display: flex;
    cursor: pointer;
    font-size: 1rem;
    position: relative;
    margin-top: 0.75rem;
    color: $color-white;
    align-items: center;
    padding-left: 0.75rem;
    &:hover .input-label:after {
      transform: scale(1.4);
    }
    .input-label {
      float: left;
      width: 12px;
      height: 12px;
      display: block;
      position: relative;
      margin-right: 1rem;
      border-radius: 100%;
      border: 1px solid $color-grey-hapi-1;
      -webkit-tap-highlight-color: transparent;
      &:after {
        content: "";
        position: absolute;
        top: 1px;
        left: 1px;
        width: 10px;
        height: 10px;
        opacity: 0.08;
        border-radius: 100%;
        transform: scale(0);
        pointer-events: none;
        background: $color-yellow-hapi-1;
        @include transition();
      }
    }
    input[type="radio"] {
      display: none;
      &:checked + .input-label:after {
        opacity: 1;
        transform: scale(1);
        transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
      }
    }
  }
}
/*END OF FORM DEFAULT STYLES*/
/*PHANTOM ADAPTER*/

.wallet-adapter-dropdown {
  .wallet-adapter-button-trigger {
    border: 1px solid $color-grey-hapi-1;
    color: $color-grey-hapi-1 !important;
    background-color: transparent !important;
    &:hover {
      color: $color-grey-hapi-2 !important;
      background-color: transparent !important;
      border: 1px solid $color-grey-hapi-2 !important;
    }
    &:before {
      background-image: url("./images/icon-pack/wallet-grey.svg");
    }
  }
}

.wallet-adapter-button:not([disabled]):hover {
  background-image: unset !important;
}

.wallet-adapter-button-trigger {
  overflow: hidden;
  margin: 0 0 0 auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 40px !important;
  padding: 0 1rem !important;
  line-height: 40px !important;
  border-radius: $br-8 !important;
  color: $color-black-hapi-1 !important;
  background-color: $color-yellow-hapi-1 !important;
  font-family: Arial, Helvetica, sans-serif !important;
  @include transition;
  &:hover {
    background-color: $color-yellow-hapi-2 !important;
  }
  &:before {
    content: "";
    width: 24px;
    height: 24px;
    margin-right: 0.5rem;
    display: inline-block;
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("./images/icon-pack/wallet.svg");
  }
  i {
    display: none;
  }
}

.wallet-adapter-modal-button-close {
  top: 8px;
}

.wallet-adapter-modal-title {
  margin-top: 0;
  line-height: 1;
  font-weight: 700;
  text-align: left;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: $color-white;
  text-transform: capitalize;
  font-family: Arial, sans-serif;
}

.wallet-adapter-modal-wrapper-no-logo {
  border-radius: 16px;
  padding: 1rem 0.75rem 0.75rem 0.75rem;
  background-color: $color-black-hapi-3;
  ul.wallet-adapter-modal-list {
    margin-bottom: 0;
    li {
      button {
        height: 56px;
      }
    }
  }
}
/*END OF PHANTOM ADAPTER*/
/*ADAPTIVE*/

@import "./styles/media/lg-992-1199.scss";
@import "./styles/media/md-768-991.scss";
@import "./styles/media/sm-576-767.scss";
@import "./styles/media/xs-less-575.scss";
/*END OF ADAPTIVE*/
