.edit-button {
  border: 0;
  padding: 0;
  cursor: pointer;
  -webkit-appearance: none;
  background-color: transparent;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
