footer {
  display: flex;
  flex-shrink: 0;
  padding: 2rem 0;
  align-items: flex-end;

  div {
    width: 50%;

    &:first-child {
    }
    &:last-child {
      div.copyright {
        width: 100%;
        margin-top: 2rem;
        text-align: right;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  footer {
    display: block;

    div {
      width: 100%;

      div {
        &.link-list {
          margin-bottom: 2rem;
          span {
            a {
              justify-content: center;
            }
          }
        }

        &.social-network.right {
          margin: 0 auto;
          justify-content: center;
        }

        &:last-child {
          &.copyright {
            text-align: center;
          }
        }
      }
    }
  }
}
