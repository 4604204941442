@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

/* UL */

.tabs-wrapper {
  margin-top: 3rem;
  text-align: left;
  margin-bottom: 2rem;
  ul {
    margin: 0;
    padding: 0;
    max-width: 100%;
    list-style: none;
    overflow-x: auto;
    white-space: nowrap;
    display: inline-block;
    box-sizing: border-box;
    padding-bottom: 0.75rem;
    &.vertical-menu {
      li {
        margin: 0;
        display: block;
        margin-bottom: 2.5rem;
        a {
          font-size: 1.75rem;
        }
      }
    }

    li {
      border-radius: 20px;
      margin-right: 1.5rem;
      display: inline-block;
      &:last-child {
        margin-right: 0;
      }
      a {
        @include transition;
        font-weight: 700;
        font-size: 1.5rem;
        position: relative;
        display: inline-block;
        text-decoration: none;
        color: $color-grey-hapi-1;
        &:after {
          @include transition;
          content: "";
          position: absolute;
          left: 50%;
          width: 0px;
          height: 3px;
          bottom: -12px;
          transform: translateX(-50%);
          background-color: $color-yellow-hapi-1;
        }
        &.current {
          &:after {
            width: 35px;
          }
          color: $color-yellow-hapi-1;
        }
        &:hover {
          &:after {
            width: 35px;
          }
          color: $color-yellow-hapi-1;
        }
      }
    }
  }
}

/*ADAPTIVE*/

@media screen and (min-width: 992px) and (max-width: 1199px) {
}

@media screen and (min-width: 768px) and (max-width: 991px) {
}

@media screen and (min-width: 576px) and (max-width: 767px) {
}

@media screen and (max-width: 575px) {
  .tabs-wrapper {
    ul {
      li {
        a {
          font-size: 1rem;
        }
      }
    }
  }
}

/*END OF ADAPTIVE*/
