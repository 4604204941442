@import "../../../../styles/variables.scss";

div.title-close {
  top: 0;
  display: flex;
  padding: 0.75rem;
  position: sticky;
  justify-content: space-between;
  background-color: $color-black-hapi-3;

  p {
    margin: 0;
    width: 90%;
  }
  svg {
    cursor: pointer;
  }
}
