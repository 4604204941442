@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.social-network {
  display: flex;
  &.left {
    margin: 0 auto 0 0;
    justify-content: start;
  }
  &.center {
    margin: 0 auto;
    justify-content: center;
  }
  &.right {
    margin: 0 0 0 auto;
    justify-content: flex-end;
  }

  span.network-wrapper {
    position: relative;
    margin-right: 1.5rem;

    &:last-child {
      margin-right: 0;
      padding-right: 0;

      span {
        &.icon {
          margin-right: 0;
        }
      }
    }

    a {
      display: flex;
      font-size: 1rem;
      font-weight: 500;
      align-items: center;
      color: $color-white;
      text-decoration: none;
      @include transition();

      &:hover {
        opacity: 0.7;
      }

      span {
        &.icon {
          line-height: 0;
          margin-right: 0.5rem;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .social-media {
    span.media-wrapper {
      margin-right: 0.5rem;
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .social-media {
    span.media-wrapper {
      margin-right: 0.5rem;
    }
  }
}

@media screen and (max-width: 575px) {
  .social-media {
    span.media-wrapper {
      margin-right: 0.5rem;
    }
  }
}
