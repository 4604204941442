@import "../../styles/variables.scss";

.message-toast {
  padding: 0.75rem;
  text-align: center;
  border-radius: $br-8;
  background-color: $color-grey-hapi-8;

  &.alert {
    background-color: $color-red-hapi-3;

    p {
      text-align: center;
      color: $color-red-hapi-1;
    }
  }

  p {
    margin: 0;
    font-weight: 400;
    text-align: left;
    font-size: 0.875rem;
    color: $color-white;
    line-height: 1.25rem;
  }

  button {
    margin: 1.5rem 0 0.75rem 0;
  }

  div.countdown-timer {
    margin-top: 1rem;
  }
}
