@mixin transition {
    transition: 0.5s ease all;
}

@mixin two-blocks-inline($leftWidth, $rightWidth, $display: flex) {
    .two-blocks-inline {
        display: $display;
        div {
            &:first-child {
                width: $leftWidth;
                margin-right: 1.5rem;
            }
            &:last-child {
                width: $rightWidth;
                @if $display !=flex {
                    margin-top: 1.25rem;
                }
            }
        }
    }
}