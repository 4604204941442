@import "../../styles/variables.scss";
.alert-main {
  .Toastify__toast-container {
    position: absolute;
    left: 0;
    width: 100%;
    div {
      padding: 0;
      margin: 0 auto;
      background: transparent;

      div {
        display: flex;
        font-weight: 700;
        text-align: left;
        padding: 0.375rem;
        font-size: 0.875rem;
        align-items: center;
        color: $color-white;
        border-radius: $br-8;
        &.fail {
          background-color: $color-red-hapi-1;
          svg {
            width: 17px;
            height: 17px;
          }
        }
        &.sucess {
          background-color: $color-green-hapi-1;
        }
        span {
          margin-left: 0.375rem;
        }
      }
    }
  }
}

.with-toast-alert {
  .Toastify__progress-bar {
    background: transparent;
  }
  .Toastify {
    > div {
      > div {
        padding: 0;
        min-height: unset;
        border-radius: $br-8;
        > div {
          margin: 0;
          padding: 0;
          div {
            div {
              display: flex;
              font-weight: 700;
              text-align: left;
              padding: 0.375rem;
              font-size: 0.875rem;
              align-items: center;
              color: $color-white;
              &.fail {
                background-color: $color-red-hapi-1;
                svg {
                  width: 17px;
                  height: 17px;
                }
              }
              &.sucess {
                background-color: $color-green-hapi-1;
              }
              span {
                margin-left: 0.375rem;
              }
              svg {
                path {
                  fill: $color-white;
                }
              }
            }
          }
        }
      }
    }
  }
}
