/*COLORS*/

$color-red: #ff6565;
$color-green: #65ffac;
$color-black: #000000;
$color-white: #ffffff;
$color-yellow: #ffff00;
$color-dark-asphalt: #202020;
$color-asphalt-black: #2d2b2f;

/*END OF COLORS*/

/*PALETTE*/

$color-yellow-hapi-1: #fdee2d;
$color-yellow-hapi-2: #fbde08;
$color-yellow-hapi-3: #f9cf01;
$color-yellow-hapi-4: #3b3a26;
$color-yellow-hapi-5: #524f28;
$color-yellow-hapi-6: #7c7629;
$color-yellow-hapi-7: #847e31;
$color-grey-hapi-1: #b3b3b3;
$color-grey-hapi-2: #9e9e9e;
$color-grey-hapi-3: #888888;
$color-grey-hapi-4: #4d4d4d;
$color-grey-hapi-5: #5f5f5f;
$color-grey-hapi-6: #383838;
$color-grey-hapi-7: #4f4f4f;
$color-grey-hapi-8: #343434;
$color-black-hapi-1: #131313;
$color-black-hapi-2: #424242;
$color-black-hapi-3: #262626;
$color-white-hapi-2: #f0f0f0;
$color-white-hapi-3: #d1d1d1;
$color-green-hapi-1: #4ebc66;
$color-green-hapi-2: #2e4432;
$color-green-hapi-3: #366240;
$color-red-hapi-1: #f65151;
$color-red-hapi-2: #7a3737;
$color-red-hapi-3: #3f2b2b;
$color-blue-hapi-1: #1e4e7a;

/*END OF PALETTE*/

/*BORDER RADIUS*/

$br-2: 2px;
$br-8: 8px;
$br-16: 16px;
$br-20: 20px;
$br-24: 24px;

/*END OF BORDER RADIUS*/
