@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.link-list {
  width: 100%;
  span.link-wrapper {
    display: block;
    margin-top: 1.25rem;

    &:first-child {
      margin-top: 0;
    }

    a {
      display: flex;
      font-size: 1rem;
      font-weight: 700;
      align-items: center;
      text-decoration: none;
      color: $color-grey-hapi-1;
      @include transition();

      svg {
        margin-left: 0.5rem;
        path {
          @include transition();
        }
      }

      &:hover {
        color: $color-white;

        svg {
          path {
            fill: $color-white;
          }
        }
      }
    }
  }
}
