@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";
div.toggle-wrapper {
  &.with-text {
    display: flex;
    justify-content: space-between;

    > span {
      font-size: 1rem;
      color: $color-white;
    }
  }
  label.switch {
    width: 48px;
    height: 24px;
    position: relative;
    display: inline-block;
    input {
      display: none;
      &:checked + .slider {
        background-color: $color-yellow-hapi-6;
      }
      &:checked + .slider {
        &:before {
          transform: translate(22px, -50%);
          -ms-transform: translate(22px, -50%);
          -webkit-transform: translate(22px, -50%);
          background-color: $color-yellow-hapi-1;
        }
      }
    }
    .slider {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      cursor: pointer;
      background-color: $color-grey-hapi-4;
      @include transition();
      &.round {
        border-radius: 12px;
        &:before {
          border-radius: 50%;
        }
      }
      &:before {
        position: absolute;
        content: "";
        top: 50%;
        left: 4px;
        width: 18px;
        height: 18px;
        transform: translateY(-50%);
        background-color: $color-grey-hapi-1;
        @include transition();
      }
    }
  }
}
