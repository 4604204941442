@import "../../../styles/variables.scss";

div.add-address {
  padding: 1rem 0.75rem;
  border-radius: $br-16;
  margin-bottom: 2.1875rem;
  background-color: $color-grey-hapi-6;

  div.input-wrapper {
    label {
      input,
      select {
        background-color: #505050;
      }
    }
  }
}

button {
  &.confirm-address {
    margin-bottom: 0.5rem;
  }
}

div.input-wrapper {
  padding: 0.75rem 0.75rem 0 0.75rem;
  label {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
    &.address {
      &.success {
        &:after {
          background-image: url("../../../images/icon-pack/check-address-success.svg");
        }
      }
      &.fail {
        &:after {
          background-image: url("../../../images/icon-pack/check-address-fail.svg");
        }
      }
      &.loading {
        &:after {
          background-image: url("../../../images/icon-pack/loading.svg");
          animation: loading 1s infinite linear;
          @keyframes loading {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }
      }
      position: relative;
      input {
        padding-right: 4.375rem;
      }
      &:after {
        content: "";
        top: 27px;
        right: 8px;
        width: 23px;
        height: 23px;
        position: absolute;
        display: inline-block;
        background-size: 23px;
      }
    }
  }
  div.radio-group {
    margin-bottom: 2rem;
  }
}
