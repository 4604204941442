header {
  padding-top: 2.5rem;
  &.admin-page {
    margin-bottom: 3.125rem;
    div {
      &.logo {
        svg {
          max-width: 100%;
        }
      }
      .menu {
        .tabs-wrapper {
          text-align: center;
          ul {
            li {
              a {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }
  div.row {
    div {
      &.controls {
        text-align: right;
      }

      div.hamburger-menu {
        div.hamburger-navigation {
          div.tabs-wrapper {
            ul.vertical-menu {
              li {
                margin-bottom: 2.5rem;
                a {
                  font-size: 1.75rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

/*ADAPTIVE*/

@media screen and (min-width: 992px) and (max-width: 1199px) {
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  header {
    div.row {
      div {
        &.logo {
          svg {
            width: auto;
            height: 72px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 776px) {
  header {
    div.row {
      div {
        &.logo {
          svg {
            width: auto;
            height: 72px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  header {
    div.row {
      div {
        &.logo {
          svg {
            width: auto;
            height: 48px;
          }
        }
      }
    }
  }
}

/*END OF ADAPTIVE*/
