@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";
div.cases-wrapper {
  padding-bottom: 3.125rem;
}

div.case {
  text-align: left;
  margin-bottom: 1rem;
  padding: 1.25rem 1rem;
  border-radius: $br-16;
  background-color: rgba(179, 179, 179, 0.12);
  &:last-child {
    margin-bottom: 0;
  }
  div.name-status-collapse {
    display: flex;
    align-items: center;
    span.name {
      font-size: 1rem;
      font-weight: 700;
      overflow: hidden;
      color: $color-white;
      white-space: nowrap;
      text-overflow: ellipsis;
      @include transition;

      &.enable {
        cursor: pointer;
        &:hover {
          color: $color-yellow-hapi-1;
        }
      }
    }
    span.is-open-status {
      margin: 0 0 0 auto;
    }
  }
  div.additional-info {
    margin-top: 0.75rem;
    span {
      font-size: 1rem;
      font-weight: 400;
      margin-left: 0.5rem;
      align-items: center;
      display: inline-flex;
      color: $color-grey-hapi-1;
      &:first-child {
        margin-left: 0px;
      }
      &:last-child {
        &:after {
          content: unset;
        }
      }
      &:after {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        margin-left: 0.5rem;
        display: inline-block;
        background-color: $color-grey-hapi-1;
      }
    }
  }
  div.case-content {
    .case-content-items {
      margin-top: 1rem;
    }
    button {
      margin: 0 auto;
      margin-top: 1rem;
    }
  }
}
