// @import '../../styles/variables.scss';
@import '../../../styles/variables.scss';
.balance {
    margin: 1rem 0;
    padding-left: 0.75rem;
    p {
        margin: 0;
        &:first-child {
            font-weight: 400;
            font-size: 0.75rem;
            margin-bottom: 0.5rem;
            color: $color-grey-hapi-1;
        }
        &:last-child {
            font-size: 1rem;
            font-weight: 400;
            color: $color-white;
            text-transform: uppercase;
        }
    }
}