@media screen and (max-width: 575px) {
    /*TYPOGRAPHY*/
    h1 {
        font-size: 1.6875rem;
    }
    /*END OF TYPOGRAPHY*/
    /*FORM*/
    form {
        .radio-group {
            .radio-group-item {
                label {
                    padding: 1.125rem 1rem;
                }
            }
        }
    }
    /*END OF FORM*/
}