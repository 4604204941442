@import "../../../styles/variables.scss";

.confirm_address {
  div.text-container {
    padding: 0.25rem 0.75rem 0 0.75rem;
  }

  div.input-wrapper {
    padding-top: 0;
    margin-top: 1.5rem;
  }

  div.address-info {
    padding: 0;
    margin-top: 1rem;
    background-color: transparent;

    div {
      display: flex;
      align-items: center;
      span {
        &:first-child {
          font-size: 0.75rem;
          color: $color-white;
        }
      }
    }
  }
}
