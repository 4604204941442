@import "../../../styles/variables.scss";

.presentation_input {
  margin: 0;
  font-size: 0.85rem;
  color: $color-white;
  padding: 0.7rem 0.75rem;
  background-color: transparent;

  &.with_label {
    margin-top: 0.25rem;
  }
  &:disabled {
    opacity: 1;
  }
}
