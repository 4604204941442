@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";
div.table-wrapper {
  padding: 0.5rem;
  margin-top: 1.5rem;
  border-radius: $br-8;
  background-color: $color-black-hapi-3;
}

table {
  width: 100%;
  border-collapse: collapse;
  thead {
    tr {
      th {
        font-weight: 400;
        padding: 0.5rem 0;
        text-align: right;
        font-size: 0.75rem;
        color: $color-grey-hapi-1;
        &.align-left {
          text-align: left;
        }
        &:first-child {
          text-align: left;
          padding-left: 0.5rem;
        }
      }
    }
  }
  tbody {
    tr {
      @include transition;
      &:hover {
        background-color: $color-grey-hapi-8;
        td {
          &.control {
            &.control-showable {
              opacity: 1;
            }
          }
        }
      }
      td {
        font-weight: 400;
        text-align: right;
        font-size: 0.75rem;
        color: $color-white;
        padding: 1rem 0 1rem 0.3125rem;
        &.bold {
          font-size: 1rem;
          font-weight: 700;
        }
        &.align-left {
          text-align: left;
        }
        &:first-child {
          font-size: 1rem;
          font-weight: 700;
          text-align: left;
          padding-left: 0.5rem;
          border-radius: $br-8 0 0 $br-8;
        }
        &:last-child {
          padding-right: 0.5rem;
          border-radius: 0 $br-8 $br-8 0;
        }
        &.control {
          &.control-showable {
            @include transition;
            opacity: 0;
          }
        }
      }
    }
  }
}
