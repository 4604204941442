@import "../../../../styles/variables.scss";
span.is-open-status {
    line-height: 1;
    font-size: 1rem;
    font-weight: 700;
    border-radius: 45px;
    align-items: center;
    display: inline-flex;
    padding: 0.4375rem 0.625rem;
    svg {
        margin-right: 0.375rem;
    }
    &.open {
        background-color: $color-green-hapi-2;
        span {
            color: $color-green-hapi-1;
        }
    }
    &.closed {
        background-color: $color-grey-hapi-5;
        span {
            color: $color-grey-hapi-1;
        }
    }
}