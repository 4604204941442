div.loader {
  padding: 11.25rem 0;

  &.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    padding: 0;
    transform: translate(-50%, -50%);
  }
}
