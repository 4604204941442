@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
.check-address {
  .Toastify {
    div.address-info {
      padding: 0;
      width: 100%;
      margin-top: 1rem;
      background: unset;

      div {
        justify-content: center;
      }
    }
    > div {
      .for-check {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.6);
        @include transition;

        > div {
          > div {
            padding: 0;
            > div {
              padding: 0;
              width: 100%;
              display: block;
              font-weight: 400;
              text-align: center;
            }
          }
          flex: none;
          width: 380px;
          padding: 20px;
          max-width: 100%;
          border-radius: $br-20;
          background-color: $color-black-hapi-3;
          p {
            font-size: 16px;
            font-weight: 700;
            margin: 0 0 1rem 0;
            color: $color-white;
          }
          div.address-info {
            span {
              font-weight: 400;
            }
            div.confirm-block {
              padding: 0;
              div {
                padding: 0;

                svg {
                  margin: 0;
                  height: auto;
                  width: 0.75rem;
                }
              }
            }
          }
          svg {
            width: 45px;
            height: auto;
            margin-bottom: 20px;
          }
          button {
            margin-top: 1.25rem;

            &.confirm-address {
              margin-bottom: 0;
              margin-top: 1.5rem;
            }
            svg {
              margin: 0;
              width: 20px;
              height: auto;
            }
          }
        }
      }
    }
  }
}
