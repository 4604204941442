@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.dropdown-menu {
  position: relative;

  button {
    &.with-icon.two-icons {
      svg {
        &:first-child {
          margin-right: 0.5rem;
        }
        &:last-child {
          transform: rotate(90deg);
        }
      }
    }
  }

  .dropdown-content {
    right: 0;
    z-index: 9;
    width: 320px;
    min-width: 100%;
    position: absolute;
    border-radius: $br-24;
    padding: 1rem 0.75rem;
    top: calc(100% + 20px);
    box-sizing: border-box;
    background-color: $color-black-hapi-3;
    @include transition();
  }
}
