@import "../../styles/variables.scss";

.confirm_block {
  display: flex;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
    svg {
      margin-right: 0.25rem;
    }
    span {
      font-size: 0.75rem;
      color: $color-white;
    }
  }

  button {
    font-weight: 700 !important;

    &:disabled {
      color: $color-yellow-hapi-5 !important;
    }
  }
}
