@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.blockchain-label {
  font-weight: 400;
  font-size: 0.75rem;
  color: $color-white;
  align-items: center;
  border-radius: $br-2;
  display: inline-flex;
  padding: 0.06rem 0.25rem;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid $color-grey-hapi-3;

  span {
    &.icon {
      line-height: 0;
      margin-right: 0.3rem;

      svg {
        path {
          fill: $color-white;
        }
      }
    }
  }
}
