@import "../../styles/variables.scss";

div.hamburger-menu {
  label.menu-label {
    position: relative;
    width: 3rem;
    height: 3rem;
    z-index: 3;
    margin: 0 auto;
    cursor: pointer;
    text-align: center;
    border-radius: 50%;
    background-color: $color-yellow-hapi-1;

    span.hamburger-icon {
      width: 1rem;
      height: 2px;
      position: relative;
      margin-top: 1.5rem;
      transition: all 0.3s;
      display: inline-block;
      background-color: $color-black-hapi-1;
      &::before,
      &::after {
        content: "";
        left: 0;
        width: 1rem;
        height: 2px;
        position: absolute;
        transition: all 0.3s;
        display: inline-block;
        background-color: $color-black-hapi-1;
      }

      &::before {
        top: -0.4rem;
        transform: rotate(0);
      }

      &::after {
        top: 0.4rem;
        transform: rotate(0);
      }

      &.clicked {
        background-color: transparent;
        &::before {
          top: 0;
          transform: rotate(135deg);
        }
        &::after {
          top: 0;
          transform: rotate(-135deg);
        }
      }
    }
  }
  div.nav-background {
    position: fixed;
    top: 6.5rem;
    width: 6rem;
    height: 6rem;
    z-index: 2;
    right: 6.5rem;
    border-radius: 50%;
    transform: scale(0);
    transition: transform 0.8s;
    background-image: radial-gradient($color-black-hapi-3, $color-black-hapi-3);

    &.clicked {
      transform: scale(80);
    }
  }
  div.hamburger-navigation {
    position: fixed;
    top: 0;
    right: 0;
    width: 0;
    opacity: 0;
    z-index: 2;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: width 0.8s, opacity 0.8s;

    &.clicked {
      opacity: 1;
      width: 100%;
    }
  }
}
